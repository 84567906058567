import * as React from "react";
import styled from "@emotion/styled";
import { privacyPolicyPageTexts } from "./texts";
import Layout from "./Layout";

const Privacy = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding: 3%;
  text-align: justify;
  padding-bottom: 3em;
  font-size: 0.8em;

  h1,
  h2 {
    text-align: center;
    margin-bottom: 0.66em;
  }

  h1 {
    font-size: 1.8em;
  }

  h2 {
    font-size: 1.2em;
    margin: 1.66em 0 0.66em 0;
  }

  ul,
  ol,
  p {
    font-size: 0.9rem;
  }

  ol {
    li {
      margin-bottom: 0.66em;
      list-style: decimal;
    }
  }

  ul {
    padding-left: 1.6em;
    li {
      margin-bottom: 0em;
      list-style: circle;
    }
  }
`;

const PrivacyPageEN = (props) => {
  const { lang } = props;

  return <Layout lang={lang} hideFooter><Privacy>{privacyPolicyPageTexts[lang]}</Privacy></Layout>;
};

export default PrivacyPageEN;
